@import "../../variables";

.subscription {
  min-width: 270px;

  .subscription-card {
    max-width: 350px;
    margin: 10px auto;
    padding: 2rem;

    .subscription-content {
      h3 {
        margin-top: 1rem;
      }

      span {
        font-size: 0.7rem;
      }

      button {
        margin-top: 1.5rem;
      }

      h6 {
        font-weight: bold;
        margin-top: 2rem;
      }

      p {
        font-size: 0.8rem;
      }
    }
  }
}

.card-free {
  .gray-info-box {
    border: 1px solid #d0d1e445;
    background: #faf9fe6b;

    h6, p {
      color: $textGray;
    }
  }
}

.card-basic {
  .gray-info-box {
    border: 1px solid #d0d1e47a;
    background: #faf9fe9e;
  }
}