$mainBlue: #2983fd;
$verificationGreen: #07B997;
$lightBlue: #A7CCFF;
$lightGreen: #27E3B6;
$lightgray: #F3F1FD;
$white: #fff;
$black: #000;
$borderGray: #D0D1E4;
$textGray: #95989A;
$textDarkerGray: #4D535A;
$whiteGray: #FAF9FE;
$lightRed: #FF2626;
$spacing1: 0.25rem;
$spacing2: 0.5rem;
$spacing3: 1rem;
$spacing4: 1.25rem;
$spacing5: 1.5rem;
$spacing6: 2rem;
$spacing7: 2.25rem;
$spacing8: 2.5rem;
$spacing9: 3rem;
$spacing10: 3.5rem;
$fontColor: #0D0D0DDD;
$fontFamily: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;
// ------------

$ProfileInfoBackgroundGray: #fafafa;
// ------------

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100');