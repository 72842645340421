.checklist-icon {
  background: #27E3B6;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  left: 0;
  top: 1px;
  margin-right: 1rem;
  justify-content: center;
  align-items: center;

  svg {
    margin: 4.5px;
  }
}
