// .ww {
//     border: 1px solid blue;
//     background-color: red;
// }

// .zz {
//     border: 1px solid blue;
//     height: 200px;
//     width: 200px;
// }

.bg {
    // border: 1px solid blue;
    height: 100%;
    width: 100%;
}

.main_div {
    // border: 2px solid salmon;
    position: relative !important;
    height: 70vh;
}


.title {
    position: absolute !important;
    // width: 200px;
    // height: 100px;
    // border: 1px solid red;

    // top: 0;
    left: 10%;
    bottom: 0;
    // transform: translate(0, -50%);

}

.description {
    position: absolute !important;
    width: 50%;
    height: 80%;
    // border: 1px solid red;

    // top: 0;
    left: 2%;
    top: 50%;
    transform: translate(0, -50%);
}

.description_title {
    color: #2983fd;
    ;
    font-weight: 900;
    font-size: 4.5rem;
}

.description_text {
    font-size: 1rem;
    font-weight: 600;

    margin-top: 1rem;
}

.button {
    // position: absolute !important;


    border: 1px solid #2983fd;
    background-color: #2983fd;
    color: white;
    font-size: 0.8rem;
    padding: 0.5rem 1.7rem;
    border-radius: 3px;

    margin-top: 2rem;
    // left: 2%;
    // bottom: 0;
    // transform: translate(0, -50%);

}

.link{
    color: white;
}

.link:hover{
    color: white;
}
.link:link { text-decoration: none; }