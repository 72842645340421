@import '../../../variables';

.bor_b {
    border: 1px solid blue;
}

.bor_o {
    border: 1px solid orange;
}

.verification_green{
    // color: #07B997;
    color: $verificationGreen;
}

.img {
    width: 1.6rem;
    height: 1.6rem;
    margin: 0px 0.6rem 0px 0.6rem;
}

//------------------------------------
.next,
.back {
    display: none;
    // justify-content: center;
    // align-items: center;

    // border: 3px solid green; 
}

//------------------------------------


.hide {
    display: block !important;
}

.show {
    display: block !important;
}



@media screen and (max-width: 768px) {
    // .show {
    //     border: 4px solid blue;
    // }

    .next,
    .back {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hide {
        display: none !important;
    }

}