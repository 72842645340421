@import "../../variables";

.dots-menu {
  margin-top: 1.7rem;
  margin-bottom: 0.5rem;
  width: 100%;

  div {
    font-size: 30px;
    color: $mainBlue;
    line-height: 0.5;
  }

  &:hover {
    text-decoration: none;
  }
}

.user-box-dots {
  position: absolute;
  right: 0;
  top: -40px;
}

.dots-vertical {
  transform: rotate(90deg);
  height: 20px;
  margin-left: 1rem;

  .dots-menu {
    width: 0;
    margin: 0;

  }

}

.dots-dropdown {
  position: absolute;
  background: $white;
  border-radius: 5px;
  padding: 1rem;
  right: 15px;
  top: 50px;
  box-shadow: 5px 6px 20px -5px rgba(0, 0, 0, 0.3);
  z-index: 10;
}