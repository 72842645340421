@import '../../variables';

.network-action {
  border-right: 1px solid $borderGray;
  padding-top: 2rem;

  .relation {

    .relation-img {
      padding: 0.5rem;
      margin-right: 1rem;

      img {
        border-radius: 100%;
        width: 5.5rem;
        height: 5.5rem;
        object-fit: cover;

      }
    }

    .relation-content {
      border-bottom: 1px solid $borderGray;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

    .relation-action {
      padding-right: 1.5rem;
      position: relative;

      .dots-dropdown {
        position: absolute;
        background: $white;
        border-radius: 5px;
        padding: 1rem;
        right: 15px;
        top: 50px;
        box-shadow: 5px 6px 20px -5px rgba(0, 0, 0, 0.3);
        z-index: 10;
      }
    }
  }
}

.network-suggestions {
  padding: 2rem 0.5rem;

  .suggestion {
    border: 1px solid $borderGray;
    padding-left: 1rem;
    border-radius: 5px;
    margin-top: 2rem;

    .suggestion-img {
      padding: 0.5rem;
      margin-right: 1rem;

      img {
        border-radius: 100%;
        width: 5.5rem;
        height: 5.5rem;
        object-fit: cover;

      }
    }

    .suggestion-content {
      border-bottom: 1px solid $borderGray;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;

      span {
        font-size: 0.8rem;
      }
    }

    .suggestion-action {
      padding: 1rem;
    }
  }
}