.modal-backdrop {
  background: #00000070;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-title {
    font-weight: bold;
  }

  .modal-body {
    background: white;
    width: fit-content;
    height: auto;
    margin: 3rem;
    padding: 1.5rem;
    border-radius: 5px;
    max-width: 480px;
    min-width: 300px;
  }

  .modal-large {
    max-width: 780px;
  }
}

// mine added
.maxWidth800 {
  max-width: 800px !important;
}

.modal-body-absolute {
  position: absolute;
  top: 10px;
  bottom: 10px;
}
.modal-body-overflow {
  overflow-y: auto;
}
