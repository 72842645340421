@import '../../variables';

.gray-info-box {
  border: 1px solid $borderGray;
  border-radius: 10px;
  width: 100%;
  padding: 2rem 1rem;
  background: $whiteGray;
  margin-bottom: 3rem;

  .user-info {
    position: relative;

    span {
      font-size: 0.8rem;
    }
  }

  .user-box-links {
    margin-top: 1rem;
  }
}

.user-info-box {
  margin-top: 0.5rem;
}

.user-info-box-margin {
  // margin-top: 3rem !important; // 0
  margin-top: 2rem !important;
}

.no-border{
  border: 1px solid transparent;
}
.no-background{
  background: transparent !important;
}

// just for test
.b-border{
  border: 1px solid blue !important;
}