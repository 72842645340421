@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import './variables';


// mt-3r

.g-opportunity-placesAutocomplete-input {

  width: 100%;
}

//=======================================================
.g-company-alias-create-introduction-logo-container {
  display: flex;
}

.g-company-alias-create-introduction-logo-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
}

.g-company-alias-create-introduction-logo-img {
  border-radius: 100%;
  // border: 1px solid red;
  border: 1px solid #f3f3f3;
  // height: 100%;
  // width: 100%;
  height: 8vw;
  width: 8vw;
}

.g-company-alias-create-introduction-update-logo-div {
  position: relative;
  width: 60%;
}

.g-company-alias-create-introduction-update-logo-button {
  position: absolute;
  top: 40%;
  right: 0px;
  // transform: translate(-50%, -50%);
  transform: translateY(-50%);
  font-size: 0.8rem;
  font-weight: 500;
  white-space: nowrap;
  background-color: $mainBlue;
  color: white;
  // padding: 0.7rem 4rem;

  padding: 0.7rem 0rem;
  width: 80%;

  border: 1px solid $mainBlue;
  border-radius: 4px;
}

//=======================================================
.g-px-6-0 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.g-alias-create-introduction-title {
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  // padding: 0.2rem 1rem;
  margin: 0.6rem 0rem;
  font-size: 1.2rem;
  font-weight: 700 !important;
  color: black !important;
}

//================================================
// multy data input in opportunity

// .upload_multy_data {
//   font-size: 1rem;
// }
// input[type=file]::file-selector-button {
//   border: 2px solid $mainBlue;
//   color: white;
//   font-size: 0.8rem;
//   padding: .6em .9em;
//   border-radius: .5em;
//   background-color: $mainBlue;
//   margin-right: 1rem;
// }
//================================================

.bbb {
  background-color: yellowgreen;
}

.bac {
  background-color: rgb(159, 159, 240);
}

.bac-r {
  background-color: rgb(248, 153, 144);
}

.bac-g {
  background-color: rgb(183, 236, 136);
}


.cursor-pointer {
  cursor: pointer;
}

.bor {
  border: 1px solid blue;
}

.bor-r {
  border: 1px solid red;
}

.bor-o {
  border: 1px solid orange;
}

.bor-g {
  border: 1px solid green;
}

.bor-c {
  border: 1px solid cyan;
}

.bor-s {
  border: 1px solid salmon;
}

.g-border-gray-radius-5x {
  border: 1px solid gray;
  border-radius: 5px;
}

// .margin-t-5-res {
//   margin-top: 3rem !important;
// }

.mt-4r {
  margin-top: 4rem !important;
}

.mt-1r {
  margin-top: 1rem !important;
}

.mt-2r {
  margin-top: 2rem !important;
}

.mt-3r {
  margin-top: 3rem !important;
}

.mt-3r-0r-res {
  margin-top: 3rem !important;
}

.mt-3r-1r-res {
  margin-top: 3rem !important;
}

.mt-4r-0r-res {
  margin-top: 4rem !important;
}

.mt-7r-0r-res {
  margin-top: 7.5rem !important;
}

.alias-profile-info-phone {
  display: none;
}

.alias-profile-info-desktop {
  // display: none;
}



// INDIVIDUAL USER _ OPPORTUNITY VIEW NAVIGATION _ BOTTOM PAGE

.g-view-profile-form-title {
  font-size: 1.2rem;
  font-weight: 600;
}

.g-view-profile-form-small-title {
  font-size: 0.9rem;
  font-weight: 600;
}

.g-view-profile-form-normal-text {

  font-size: 0.8rem;
}

//-----------------------------------------------------------

// INDIVIDUAL USER _ OPPORTUNITY VIEW NAVIGATION _ BOTTOM PAGE
.g-opportunity-view-naviation-input-page-wrap {
  // height: 200px;
  // width: 200px;
  // border: 1px solid #aaa;
  // margin: 10px;
  display: flex;
  margin-left: 1.5rem;
  margin-right: 0.5rem;

  // border: 1px solid blue;

}

.g-opportunity-view-naviation-input-page-text {
  align-self: flex-end;
  font-weight: 600;

  // border: 1px solid blue;
}

.g-opportunity-view-naviation-buttons-div {
  margin-bottom: 3rem;

  // border: 1px solid blue;
}

.g-opportunity-view-select-opp-input,
.g-opportunity-view-naviation-buttons {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 1px solid blue;
  // padding: 0.2rem;
  margin: 0rem 0.3rem;
  width: 1.4vw;
  height: 1.4vw;
  font-weight: 700;
}

.g-opportunity-view-select-opp-input {
  // cursor: default;
  border: 1px solid $mainBlue;
  text-align: center !important;
  font-size: 1rem;
  font-weight: 500;

  outline: none;
}



.g-opportunity-view-select-opp-input input[type='number'] {
  -moz-appearance: textfield;
}

.g-opportunity-view-select-opp-input input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.g-opportunity-view-naviation-arrow-img {
  height: 50%;
  width: 50%;
}

.active-navigation-button {
  color: white !important;
  border: 1px solid $mainBlue;
  background-color: $mainBlue;
  border-radius: 5px;
}

@media (max-width: 768px) {

  // INDIVIDUAL USER _ OPPORTUNITY VIEW NAVIGATION _ BOTTOM PAGE
  .g-opportunity-view-naviation-input-page-wrap {

    margin-left: 0.5rem;
    margin-right: 0.2rem;
  }

  .g-opportunity-view-naviation-input-page-text {
    font-size: 0.7rem;
  }

  .g-opportunity-view-select-opp-input,
  .g-opportunity-view-naviation-buttons {

    width: 5vw;
    height: 5vw;
    font-size: 1rem;
    margin: 0rem 0.2rem;

    // border: 1px solid blue;
  }



  //-------------------------------------
  .g-company-alias-create-introduction-update-logo-button {
    padding: 0.5rem 3rem;
  }


  .g-company-alias-create-introduction-logo-img {
    height: 30vw;
    width: 30vw;
  }

  .g-px-6-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }

  // .mt-3r-res {
  .alias-profile-info-phone {
    display: block;
  }

  .alias-profile-info-desktop {
    display: none;
  }

  .mt-4r-0r-res {
    margin-top: 0rem !important;
  }

  .mt-7r-0r-res {
    margin-top: 0rem !important;
  }

  .mt-3r-0r-res {
    margin-top: 0rem !important;
  }

  .mt-3r-1r-res {
    margin-top: 1rem !important;
  }

}

// variables
.opportunity_view_experience {
  color: black !important;
  font-size: 0.8rem !important;
  margin-right: 0.4rem !important;
  // font-size: 0.8rem;
}

$newBlue: yellow;