.public-footer {
  .social-links {
    margin: 0.5rem 0;

    img {
      height: 1.5rem;
    }
  }

  .footer-links {
    margin: 0.5rem 0;

    li {
      a {
        font-size: 0.8rem;
        font-weight: 600;
      }
    }
  }

  span {
    font-size: 0.7rem;
  }
}