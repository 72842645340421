@import '../../../variables';


//--------------------------------

.loading-text-container,
.loading-container,
.icon-container {
  // border: 1px solid red;

}

.outer-loading-div {
  width: 60%;
  height: 20px;

  border-radius: 5px;
  border: 1px solid #2983fd;

}

.inner-loading-div {
  width: 50%;
  height: 100%;
  border-radius: 5px;

  background: #2983fd;
  border: 1px solid #2983fd;

}


.loading-text {

  color: black;
  font-family: $fontFamily;
  font-weight: 700;
  font-size: 15px;
}

//===========================


//---------------------------


.blue-gradient {
  background: transparent linear-gradient(38deg, #8cc3e6 0%, #2c8adc 100%) 0% 0% no-repeat padding-box;
  // background: transparent linear-gradient(38deg, #68bbeb 0%, #3da3fe 100%) 0% 0% no-repeat padding-box;
}

.green-gradient {
  background: transparent linear-gradient(38deg, #40deba 0%, #06ab8c 100%) 0% 0% no-repeat padding-box;
}

.light-purple-gradient {
  background: transparent linear-gradient(38deg, #a995d0 0%, #6b3ec5 100%) 0% 0% no-repeat padding-box;
}

.dark-blue-gradient {
  background: transparent linear-gradient(68deg, #1895dd 0%, #025c90 100%) 0% 0% no-repeat padding-box;
}

.purple-gradient {
  background: transparent linear-gradient(68deg, #685dde 0%, #2215b5 100%) 0% 0% no-repeat padding-box;
}



//---------------------------

.icons-div {
  width: 70%;
  height: auto;
  // background: blue;

  // add flexbox style
  display: flex;
  flex-direction: row;
  // justify-content: space-around; // o
  justify-content: space-evenly;
  // justify-content: space-between; 
  flex-flow: wrap;

}

.icon {
  width: 136px;
  height: 136px;
  border-radius: 22px;
  margin: 5px; // o
  box-sizing: border-box;


  // za iconite-img slikata
  // display: flex;
  // justify-content: center;
  // align-items: center;

  // new
  position: relative;
}

.asd {
  position: absolute;
  width: 100%;
  height: 100%;

  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);

  // transform: translateY(-55%);
  // transform: translateX(50%);
}



.icon-name-text {

  font-size: 0.8rem;
  color: white;

  position: absolute;
  width: 100%;
  height: 40%;
  top: 93%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;

}

.img {
  // width: 60%;
  // height: 60%;
  position: absolute;
  width: 40%;
  height: 40%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}


@media only screen and (min-width: 280px) {
  .icon-container {
    // margin-top: 40px !important;
    margin-top: 7%;
  }

  .loading-text-container {
    margin-top: 6%;
  }

  .loading-container {
    // margin-top: 40px !important;
    margin-top: 3%;
  }

  .outer-loading-div {
    width: 75%;
  }

  .loading-text {
    font-size: 13px;
  }

  .icons-div {
    width: 100%;
    justify-content: space-evenly;

    padding: 0px 8px 0px 8px;
  }

  .icon {
    width: 100px;
    height: 100px;
    margin: 15px;
  }
}


@media only screen and (min-width: 365px) {

  .icon-container {
    // margin-top: 40px !important;
    margin-top: 5%;
  }

  .loading-text-container {
    margin-top: 7%;
  }

  .loading-container {
    // margin-top: 40px !important;
    margin-top: 5%;
  }

  .icons-div {
    width: 95%;
    justify-content: space-around;
    padding: 0px;

  }



  .loading-text {
    font-size: 15px;
  }

  .icon {
    width: 136px;
    height: 136px;
    // margin: 20px;
  }
}

@media only screen and (min-width: 470px) {

  .outer-loading-div {
    width: 67%;
  }

  .icons-div {
    width: 90%;

  }

  .icon {
    width: 160px;
    height: 160px;
    margin: 20px;
  }
}

@media only screen and (min-width: 600px) {
  // .outer-loading-div {
  //   width: 67%;
  // }

  // .icons-div {
  //   width: 80%;
  //   justify-content: space-around;
  // }
  .icons-div {
    padding-left: 7%;
    padding-right: 7%;
    // padding: 0px 25px 0px 25px;
  }

  .icon {
    width: 180px;
    height: 180px;
    margin: 20px;
  }
}



@media only screen and (min-width: 768px) {

  .icon-name-text {
    font-size: 1rem;
  }

  .icon-container {
    // margin-top: 40px !important;
    margin-top: 8%;
  }

  .loading-text-container {
    margin-top: 6%;
  }

  .loading-container {
    // margin-top: 40px !important;
    margin-top: 3%;
  }

  .outer-loading-div {
    width: 65%;
  }

  .icons-div {
    width: 70%;
    justify-content: space-evenly;
    padding: 0px;
  }

  .icon {
    width: 115px;
    height: 115px;
    margin: 5px;
  }
}



@media only screen and (min-width: 992px) {

  .icon-container {
    // margin-top: 40px !important;
    margin-top: 1%;
  }

  .loading-text-container {
    margin-top: 2%;
  }

  .loading-container {
    // margin-top: 40px !important;
    margin-top: 1%;
  }


  .outer-loading-div {
    width: 60%;
  }

  .icon {
    width: 140px;
    height: 140px;
    margin: 5px;
  }
}

@media screen and (min-width: 1200px) {
  .icon-container {
    // margin-top: 40px !important;
    margin-top: 2%;
  }

  .loading-text-container {
    margin-top: 2%;
  }

  .loading-container {
    // margin-top: 40px !important;
    margin-top: 1%;
  }

  .loading-text {
    font-size: 18px;
  }

  .icon {
    width: 160px;
    height: 160px;
    margin: 5px;
  }
}


@media screen and (min-width: 1550px) {
  .icon-container {
    // margin-top: 40px !important;
    margin-top: 3%;
  }

  .loading-text-container {
    margin-top: 3%;
  }

  .loading-container {
    // margin-top: 40px !important;
    margin-top: 1%;
  }

  .icon {
    width: 180px;
    height: 180px;
    margin: 5px;
  }
}

@media screen and (min-width: 1740px) {
  .icon {
    width: 204px;
    height: 204px;
    margin: 5px;
  }
}