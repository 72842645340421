@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import './variables';

body, html {
  font-size: 15px;
  margin: 0;
  font-family: $fontFamily;
  color: $fontColor;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

p {
  font-size: 1rem;
}

ul {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
  }
}

button {
  outline: none !important;
}

.pointer {
  cursor: pointer;
}

.corner-radius5 {
  border-radius: 5px;
}

.corner-radius10 {
  border-radius: 10px;
}

.top-border-sharp {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.bottom-border-sharp {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.border-top {
  border-top: 1px solid $borderGray;
}

.border-bottom {
  border-bottom: 1px solid $borderGray;
}

.link-small {
  font-size: 0.8rem;
  color: #2983fd;
}

.color-mainBlue {
  color: $mainBlue;
}

.click-disabled {
  pointer-events: none;
  color: lightgray !important;


}

.tooltip1 {
  position: relative;
  display: inline-block;
}

.tooltip1 .tooltiptext {
  visibility: hidden;
  width: auto;
  min-width: 180px;
  max-width: 180px;
  font-size: 0.7rem;
  background-color: #00000080;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 300;
  top: -20px;
  //left: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.tooltip1:hover .tooltiptext {
  visibility: visible;
}

.tooltip1 .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #00000080 transparent transparent transparent;
}

.simple-border {
  border: 1px solid $borderGray;
}

.simple-shadow {

  box-shadow: 5px 6px 20px -5px rgba(0, 0, 0, 0.3);
}

.bg-gray {
  background: #EDEEFF;
}

.bg-white {
  background: #fff;
}

.bg-mainBlue {
  background: $mainBlue;
  color: $white;
}

.img-media-reshared {
  height: 150px;
  width: 150px;
}

.media-container-profile {
  padding: 0 3rem;
}


@media (max-width: 1000px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 640px) {
  html {
    font-size: 13px;
  }
}


@media (max-width: 1600px) {
  .img-media-reshared {
    height: 120px;
    width: 120px;
  }

  .media-container-profile {
    padding: 0;
  }

}