.ww {
    background-color: aqua;
}


.main_container {
    margin-top: 5rem;

    margin-left: 13rem;
    // instead  {/* <div className={'col-md-6 ' + OppCSS.main_container}> */}
    // width: 45%;
    // ke mora posle da se dodava responzivnost vo media query
    //---------
}

.navigation_div {
    display: none;
}


.next,
.back {
    display: flex;
    // justify-content: center;
    align-items: center;
    width: 20%;
}

.back {
    justify-content: start;
}

.navigation_title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;

    color: #2983FD;
    font-weight: 600;
}

.back_img {
    height: 20px;
    width: 20px;
}

.main_search_div {
    display: flex;
    // justify-content: space-between;
    justify-content: start;
    align-items: center;

    margin-bottom: 1.5rem;
}

.right_div_search {
    margin-left: 3rem;
    width: 53%;
}

.title {
    font-weight: 700;
    margin-bottom: 1rem;
}

.main_list_div {
    display: flex;
    justify-content: space-between;
    margin: 1.7rem 0rem;
}

// LEFT LIST DIV
.left_list_div {
    // border: 1px solid blue;
    display: flex;
    justify-content: start;
    // justify-content: center;
    width: 50%;

}

.names_wraper {
    margin-left: 1.1rem;
}

.picture {
    display: flex;
    align-items: center;
}

.img {
    width: 45px;
    height: 45px;
}

.name_div {
    display: flex;
    align-items: center;
    justify-content: start;

}

.name {
    font-weight: 800;
    color: #2983FD;
    cursor: pointer;
}

.name:hover {
    text-decoration: underline;
}

.small_text {
    font-size: 0.7rem;
}

.medium_text {
    font-weight: 700;
    font-size: 0.9rem;
}



// RIGHT LIST DIV
.right_list_div {
    display: flex;
    justify-content: space-between;
    width: 45%;
    // width: 50%;
}

.right_list_div_small {
    display: flex;
    justify-content: end;
    // justify-content: space-between;
    width: 30%;
    // width: 50%;
}

.ownership_div,
.verify_div,
.view_profile_div {
    display: flex;
    align-items: center;
    // margin-right: 6rem;
}

// momentalno resenia za dolgi iminja
.ownership_div {
    // border: 1px solid blue;
    max-width: 50%;
}

.view_profile_button {
    border-radius: 5px;
    padding: 0.5rem 1.1rem;
    font-size: 0.7rem;
    color: #2983FD;

    border: none;
    outline: none;
    background: none;

}

.button {
    border-radius: 5px;
    padding: 0.5rem 1.1rem;
    font-size: 0.7rem;
    color: white;
    background-color: #2983FD;
    // border-color: #2983FD;
    border: none;
    outline: none;

}

.verify_button {
    border-radius: 5px;
    padding: 0.5rem 2rem;
    font-size: 0.7rem;
    color: white;
    background-color: #07B997;
    border: none;
    outline: none;

    font-weight: 500;
}

.verify_button {
    color: #07B997;
    background-color: white;
}

// MEDIA QUERIES
@media (max-width: 768px) {

    .left_list_div {
        // border: 1px solid blue;
        width: 70% !important;
    }


    .navigation_div {
        display: flex;
        // justify-content: space-between;
        margin-bottom: 2rem;
    }

    .main_div {
        justify-content: center
    }

    // .main_container {
    //     margin-top: 2rem;
    //     margin-left: 0rem;
    // }

    .main_container {
        margin: 0.5rem 0rem 0rem 0rem;
        padding: 0rem 1.7rem;
    }

    .img {
        width: 45px;
        height: 45px;
    }

    .name {
        font-weight: 600;
        font-size: 1.1rem;
    }

    .small_text {
        font-size: 0.8rem;
    }

    // .medium_text {
    //     font-size: 0.6rem;
    // }

    // .date {
    //     font-size: 0.6rem;
    //     margin-right: 0.4rem;
    // }

    .names_wraper {
        margin-left: 0.9rem;
    }

    // .button,
    // .verify_button,
    // .view_profile_button {
    //     // font-size: 0.6rem;
    //     // padding: 0.4rem 0.5rem;
    //     font-size: 0.7rem;
    //     padding: 0.7rem 1.6rem;
    // }

    .verify_button {
        font-size: 0.8rem;
        padding: 0.7rem 1.5rem;
        // padding: 0.7rem 2.8rem;
    }



    .main_search_div {
        justify-content: center;
    }

    .right_div_search {
        margin-left: 0rem;
        width: 100%;
    }

    .left_div_search {
        display: none !important;
    }

    .right_list_div {
        justify-content: end;
        width: auto;
    }

    .ownership_div,
    .view_profile_div {
        display: none !important;
    }
}