@import "../../variables";

.header-menu {
  position: absolute;
  right: 10px;
  top: 90px;
  background: $white;
  width: 25%;
  min-width: 400px;
  max-width: 430px;
  z-index: 2;
  padding: 0;

  .top-actions {
    padding-top: 1rem;
  }

  .menu-content {
    padding: 1rem;
  }
}