@import '../../variables';

.tabs-btn {
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  color: $mainBlue;
  padding: 1rem 0rem;
  text-align: center;
  border-bottom: 2px solid transparent;
  position: relative;
  z-index: 1;

  &:hover {
    color: $mainBlue;
    border-color: $mainBlue;
  }
}

.tab-active {
  color: $mainBlue;
  border-color: $mainBlue;
}

.tabs-head:after {
  display: block;
  width: 100%;
  height: 1px;
  background: #D1DBE6;
  position: relative;
  left: 0;
  bottom: 0;
  content: '';
}

.p-none {
  .tabs-btn {
    padding-left: 0;
    padding-right: 0;
  }
}

.tabs-dashboard {
  //margin-top: 4rem;
}

// mine added
.tabs-margin-top {
  // margin-top: 2rem; // o
  margin-top: 1rem; // o
}

.tabs-border-bottom {
  .tabs-head {
    border-bottom: 1px solid $borderGray;
    padding: 0 1rem 1rem;
  }
}

.tabs-header-menu {
  padding: 1rem 0;

  > div {
    padding: 1rem;
    height: fit-content;
    max-height: 75vh;
    overflow-y: scroll;
  }

  .chat-img {
    margin: -15px;
  }

  ::-webkit-scrollbar {
    width: 8px;

  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $borderGray;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .tabs-btn {
    cursor: pointer;
    color: $fontColor;
    font-size: 0.8rem;
    padding: 0 .5rem;
    text-align: center;
    position: relative;
    z-index: 1;
    border-radius: 5px;
    border: none;
    font-weight: 500;
  }

  .tab-active {
    background: $mainBlue;
    color: $white;
  }

  .tabs-head:after {
    display: none;
  }
}

//--- mine added

@media screen and (max-width: 768px) {
  .tab-active {
    border-color: transparent;
  }
   
  .tabs-head:after {
   
    background: transparent;
   
  }

}