@import "src/variables";

.form-box {
  margin-bottom: 3rem;
  width: 100%;
  height: auto;

  // gore dole - levo desno
  // padding: 1rem 0.5rem; // original
  padding: 0.2rem 0.5rem; // original
  // padding: 0.5rem 0.5rem; // original
  // border: 1px solid $borderGray;



  .form-title {
    // font-size: 1.3rem; // o
    font-size: 1.2rem;
    font-weight: 600;
    // padding: 2px 10px;
    // background-color: #D0D1E4;
  }

  // mine added 
  .overview-form-title-small {
    // font-size: 1.3rem; // o
    font-size: 0.9rem;
    font-weight: 600;
    // padding: 2px 10px;
    // background-color: #D0D1E4;
  }

  .overview-div-text {

    font-size: 0.8rem;
  }
  .skills-div-text {
    color: black !important;
    font-size: 0.8rem !important;
    margin-right: 0.5rem;
  }
}



.profile-box {
  padding: 2rem 1rem;

  .profile-info {
    span {
      font-size: 0.7rem;
      color: $textGray;
    }
  }
}

// mine added
.profile-box-smaller-padding {
  padding: 0.8rem 1rem;

  .profile-info {
    span {
      font-size: 0.7rem;
      color: $textGray;
    }
  }
}


.custom-location {
  margin-top: 3.55rem;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  border: 1px solid $borderGray;
}

.border-gray {
  border: 1px solid $borderGray;
}

.title-verification-green {
  color: #07B997;
}

// mine added
.margin-bottom-small {
  margin-bottom: 1rem !important;
}

.margin-b-1 {
  margin-bottom: 1rem !important;
}

.margin-b-0 {
  margin-bottom: 0rem !important;
}

// for opportunity-create

.opportunity-lits-create-new {
  color: #2983fd;
  font-weight: 700;
}

.opportunity-lits-name {
  cursor: pointer;
  font-size: 0.7rem;
  margin: 0.6rem 0rem;
}

.opportunity-lits-div {
  margin-top: 1rem;
}