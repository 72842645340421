@import "../../variables";


.skill-tags {
  font-weight: 500 !important;
  color: $fontColor  !important;
  padding: 10;
  font-size: 12;
}

.field-icon {
  float: right;
  position: relative;
  right: 15px;
  top: -31px;
  z-index: 2;
  font-size: 14px;

  i {
    color: $mainBlue;
  }
}

@mixin agenda-tags($color, $bgColor) {
  position: relative;
  color: $color;
  background-color: $bgColor;
  margin: 3px 5px;
  border-radius: 19px;
  padding: 5px 7px;
  font-size: 0.7rem;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

@mixin inputs-label {
  .MuiInputLabel-outlined {
    position: absolute !important;
    top: -1px !important; // za da bide labelata vo centar verticalno so noviot '.MuiInputBase-input { height: 0.3rem !important }'
    // top: -6px !important; // orignial - trebase da go smenam za koa go zglolemiv height-ot na inputitie labelata pak da bide vo centart vertikalno
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, 0px) scale(0.75) !important;
  }
}


@mixin inputs-styling {
  width: 100%;

  .MuiFormLabel-root.Mui-focused {
    color: $mainBlue  !important;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: $mainBlue  !important;
    border-width: 1px !important;
  }

  .MuiOutlinedInput-root {
    border-radius: 5px;

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: $mainBlue  !important;
      }
    }
  }

  .MuiFormLabel-root {
    font-family: $fontFamily  !important;
    letter-spacing: normal;
    font-size: 0.9rem !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $borderGray;
  }

  .MuiFormLabel-root {
    color: $borderGray  !important;
  }

  .MuiInputBase-input {
    font-size: 0.9rem;
  }
}


.input {
  .MuiInputBase-input {
    // height: 0.3rem !important; // old input height
    height: 1rem !important; // new input height
  }

  @include inputs-label();

  @include inputs-styling();
}

.textarea {
  @include inputs-label();
  @include inputs-styling();
}

.dropdown {
  @include inputs-label();
  @include inputs-styling();

  // dropdown manu height
  .MuiInputBase-input {
    height: 2rem !important;
    // height: 1.3rem !important; // old dropdown manu height
  }

  .MuiOutlinedInput-input {
    padding: 10.5px 14px;
  }

  .MuiFormLabel-root {
    color: $mainBlue  !important;
  }

  .MuiSelect-icon {
    color: $mainBlue  !important;
  }
}

.tags-input {
  display: flex;
  flex-wrap: wrap;
  background-color: $white;
  border: 1px solid $borderGray;
  padding: 9px 4px;
  color: $borderGray;
  vertical-align: middle;
  border-radius: 5px;
  max-width: 100%;
  // skills input height
  line-height: 30px;
  // line-height: 22px; // original
  cursor: text;
  width: 100%;
  font-size: 0.8rem;

  &:focus {
    border-color: $mainBlue;
  }

  &:hover {
    border-color: $mainBlue;
  }

  .tag {
    @include agenda-tags($white, $mainBlue);
  }

  input {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    padding: 0 6px;
    margin: 0;
    width: -webkit-fill-available;
    max-width: inherit;

    &::placeholder {
      color: $borderGray;
      font-family: $fontFamily;
    }
  }
}

.res-tag {
  @include agenda-tags($mainBlue, $lightgray);
}

[type="date"]::-webkit-inner-spin-button {
  display: none;
}

[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

.error-message {
  color: red;
  font-size: 0.7rem;
}

//
//.tags-input .tag {
//  color: $white;
//  background-color: $mainBlue;
//  margin: 5px 8px;
//  border-radius: 19px;
//}
//
//.label {
//  display: inline;
//  padding: .2em .6em .3em;
//  font-size: 75%;
//  line-height: 1;
//  color: #fff;
//  text-align: center;
//  white-space: nowrap;
//  vertical-align: baseline;
//  border-radius: .25em;
//}

.red .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
  border-width: 1px !important;
}

.green .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: limegreen !important;
  border-width: 1px !important;
}

.xxx {
  @include inputs-label();
  @include inputs-styling();

  // transition: height 0.5s ease-in-out !important;
  .MuiInputBase-input {
    // scrollbar
    // overflow: hidden; // ne se gleda ama i ne raboti :)
    scrollbar-width: none; // /* Firefox 64 */
    -ms-overflow-style: none;

    /* Internet Explorer 11 */
    &::-webkit-scrollbar {
      /** WebKit */
      display: none;
    }

    // scrollbar
    height: 1.4rem;
   
    // transition
    transition: height 0.5s ease-in-out !important;
    // color: red ;
  }
}

.xxx .MuiInputBase-input:focus {
  height: 10.3rem !important;
  // color: aqua ;
}

// go dodadov posle ova za da smenam height koga ne e vo fokus
.xxx .MuiInputBase-input {
  height: 1rem !important;
  // color: aqua ;
}

#xx .MuiOutlinedInput-multiline {
  // padding: 10.5px 14px !important;
  // padding: 12.5px 14px !important;
}

// added just for opportunity name availability

// .a .MuiOutlinedInput-notchedOutline {
.notAvailableName .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}

.availableName .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: green !important;
  // border-color: lawngreen !important;
}


// vo dropdown many - koga ke odbereme nesto /  ova e neso kako countainer div na toa pole
.select_element_from_MUI {
  // border: 1px solid blue;
  // display: flex;
  // align-items: center;

}

// vo dropdown many - koga ke odbereme nesto
.select_element_from_MUI .MuiSelect-select.MuiSelect-select {
  // border: 1px solid blue;
  display: flex;
  align-items: center;
}
