.left_side_manu_responsive {
    // background-color: aquamarine;

    max-width: 50px;
    margin-left: 3vw;
    // margin-left: 50px;
    height: 85vh;
}

@media screen and (max-width: 768px) {
    .left_side_manu_responsive {
        background-color: salmon;
        display: none !important ;
    }
}

// just for testing
.green{
   background-color: green;
}