.header {
  // position: relative;
  // padding: 1.5rem 2rem 1.5rem 4rem;
  margin-top: 14px;
  width: 93%;

  .logo {
    img {
      height: 2.6rem;
    }
  }

  .header-icons-right-container {
    margin-right: 1rem;
  }

  .header-icons {
    div {
      padding: 1rem 1.5rem;
      // margin-left: 1rem;
      margin-right: 0.6rem;
      cursor: pointer;
      border-radius: 50%;
      width: 3rem;
      height: 3rem;

    }
  }
}

.logo-text {
  font-size: 14px;
}

@media screen and (max-width: 500px) {
  // .header-icons-right {
  //     width: 135px;
  // }

  .logo-text {
    font-size: 11px;
  }
}


@media screen and (max-width: 768px) {
  .logo {
    display: none !important;
  }

}

.header-container {
  margin-bottom: 5px;
}


@media screen and (max-width: 768px) {
  .header-container {
    margin-bottom: 25px;
  }

}

// mine added 

.align-item-center {
  align-items: center;
}

.chat-icon-div {
  justify-content: center;
  background-color: #2983FD;
  border: 1px solid #2983FD;
  border-radius: 100%;
  height: 2.2rem !important;
  width: 2.2rem !important;
  padding: 0rem !important;
  margin-left: 0.8rem;
}

.chat-icon-img {
  width: 13px;
  height: 13px;
}

.conference-icon-div {
  justify-content: center;
  background-color: #685DDE;
  border: 1px solid #685DDE;
  border-radius: 100%;
  height: 2.2rem !important;
  width: 2.2rem !important;
  padding: 0rem !important;
  margin-left: 0.8rem;
}

.conference-icon-img {
  width: 16px;
  height: 16px;
}

.language-icon-div {
  justify-content: center;
  // background-color: #685DDE;
  border: 1px solid transparent;
  border-radius: 100%;
  height: 2.2rem !important;
  width: 2.2rem !important;
  padding: 0rem !important;
  margin-left: 0.8rem;
}

.language-icon-img {
  border-radius: 100%;
  width: 100%;
  height: 100%;
}


.fixed-chat-icon-container {
  position: fixed;
  z-index: 9999;
  bottom: 80px;
  right: 85px;
  width: 150px;
}

.fixed-chat-icon-div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2983FD;
  border: 1px solid #2983FD;
  border-radius: 100%;
  height: 2.8rem !important;
  width: 2.8rem !important;
  padding: 0rem !important;
  margin-left: 0.8rem;
}

.fixed-chat-icon-img {
  width: 17px;
  height: 17px;
}

@media screen and (max-width: 768px) {
  .fixed-chat-icon-container {
   
   
    right: 30px;
    
  }

}

//-----------------------------------------
//-------------  Original -----------------
//-----------------------------------------
// .header {
//   position: relative;
//   padding: 1.5rem 2rem 1.5rem 4rem;

//   .logo {
//     img {
//       height: 3.5rem;
//     }
//   }

//   .header-icons {
//     div {
//       padding: 1rem 1.5rem;
//       margin-left: 1rem;
//       cursor: pointer;
//       border-radius: 50%;
//       width: 3rem;
//       height: 3rem;

//     }
//   }
// }