@import '../../variables';

.input-action {
  padding: 0.8rem 0;
  // padding: 0.4rem 0;
  // padding: 0.6rem 0;

  p {
    font-size: 0.7rem;
    margin-bottom: 0.6rem;
  }

  .input-action-text {
    font-size: 0.7rem;
    color: $textGray;

    label {
      color: $black;
    }

    p {
      font-size: 0.7rem;
      margin-bottom: 0.5rem;
    }
  }

  .input-action-text-left {
    font-size: 0.7rem;
    color: $borderGray;

    p {
      font-size: 0.7rem;
      margin-bottom: 0.7rem;
    }

    label {
      color: $fontColor;
    }
  }

  .text-left-dark {
    font-size: 0.7rem;
    color: $fontColor;

  }
}