@import "src/variables";

.form_box_section {
  // padding: 0.2rem 0.5rem;
  // border: 1px solid salmon;
}

.form_box_title_div {}

.form_box_title {
  background-color: #f8f8f8;
  border-radius: 5px;

  padding: 0.5rem 1rem;

  border: 1px solid #f8f8f8;
  font-size: 1.2rem;
  font-weight: 700 !important;
  color: black !important;

  display: flex;
  justify-content: space-between;
}

.opportunity_list_div {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px 0.8rem;
}

.opportunity_list_name {
  cursor: pointer;

  margin: 0.3rem 0rem 1rem 0rem;
  // margin: 0.6rem 0rem;
  font-weight: 600;
  color: black;


  //--- 
  border: 1px solid #e5e5e5;
  width: 3vw;
  height: 3vw;

  //---

  display: flex;
  justify-content: center;
  align-items: center;

  //------

  font-weight: 700;
  font-size: 1.1rem;
  // font-size: 1.2rem;
}

.opportunity_list_create_new {
  color: #2983fd;
  font-weight: 700;
}

.create_new_img {
  width: 40%;
  height: 40%;

}



// MEDIA QUERIES
@media (max-width: 768px) {
  .opportunity_list_name {
    margin: 0rem;
    width: 9vw;
    height: 9vw;
    font-size: 1rem;
    margin: 0px 0.23rem;
  }

  .opportunity_list_div {
    margin: 1rem 0rem 0rem 0rem;
    padding: 0px;
    justify-content: start;

  }

  // .create_new_img {
  //   width: 40%;
  //   height: 40%;

  // }
}