.red {
    // background-color: red;
    // border: 1px solid red;

    // background: transparent linear-gradient(38deg, #8cc3e6 0%, #2c8adc 100%) 0% 0% no-repeat padding-box;
    border-radius: 6px !important;


}

.margin_bottom {
    margin-bottom: 2rem !important;
}

// just for testing
.green {
    background-color: green;
}


// these i need
.blue-gradient {
    background: transparent linear-gradient(38deg, #85c3eb 0%, #2c8adc 100%) 0% 0% no-repeat padding-box;
    // background: transparent linear-gradient(38deg, #68bbeb 0%, #3da3fe 100%) 0% 0% no-repeat padding-box;
}

.green-gradient {
    background: transparent linear-gradient(38deg, #40deba 0%, #06ab8c 100%) 0% 0% no-repeat padding-box;
}

.light-purple-gradient {
    background: transparent linear-gradient(38deg, #a995d0 0%, #6b3ec5 100%) 0% 0% no-repeat padding-box;
}

.dark-blue-gradient {
    background: transparent linear-gradient(68deg, #1895dd 0%, #025c90 100%) 0% 0% no-repeat padding-box;
}

.purple-gradient {
    background: transparent linear-gradient(68deg, #685dde 0%, #2215b5 100%) 0% 0% no-repeat padding-box;
}

.just-blue-gradient {
    background: transparent linear-gradient(68deg, #4fa6f3 0%, #1676ca 100%) 0% 0% no-repeat padding-box;
}
.red-gradient {
    background: transparent linear-gradient(68deg, #ed5e5e 0%, #d30000 100%) 0% 0% no-repeat padding-box;
}