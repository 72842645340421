// https://stackoverflow.com/questions/8508275/how-to-center-a-position-absolute-element

.message_div {
    border-radius: 10px;
    // color: red;

    // width: 100px;
    // height: 100px;
    width: 30vw;
    height: 15vh;
    // border: 2px solid red;


    position: fixed;
    // position: absolute;
    top: 30%;
    // top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    z-index: 9000;

    // align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;


    // old
    // background-color: white;
    // color: #2983fd;
    // border: 1px solid #2983fd;

    // new
    background: transparent linear-gradient(68deg, #b23e37 0%, #7a0f09 100%) 0% 0% no-repeat padding-box;
    // background: transparent linear-gradient(68deg, #1895dd 0%, #025c90 100%) 0% 0% no-repeat padding-box;
    color: white;


    font-weight: 600;

    padding: 1.5rem 0px;
}

.message_text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.yes_no_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
}

.yes, .no {
    margin-top: 0.5rem;
   cursor: pointer;
}

@media (max-width: 768px) {

    .message_div {
        width: 75vw;
        height: 10vh;

        font-size: 1rem;
    }

    .message_text {
        font-size: 0.8rem;
    }
}