.ww {
    color: red;
    border: 1px solid red;
    display: flex;
    justify-content: center;
}

// .ww {
//     background-color: aqua;
// }

.main_container {
    
    // border: 1px solid blue;
    margin-top: 1.5rem;
    // margin-top: 2rem;
    // padding: 0rem 3rem;
    padding: 0rem 3.9vw;
    // padding: 0rem 3vw;
}

.main_search_div {
    // border: 1px solid blue;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 1rem;
    margin-bottom: 2.5rem;
}

.right_div_search {
    width: 100%;
    // width: 80%;
}

// .button_div .button {
//     margin-right: 0.5rem;
// }


.title {
    // border: 1px solid blue;
    font-weight: 700;
    font-size: 1.3rem;
    // font-size: 1.4rem;
    // font-size: 1.1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.main_list_div {
    
    display: flex;
    justify-content: space-between;
    margin: 1.6rem 0rem;
    // margin: 2rem 0rem;
    // margin: 2.7rem 0rem;
   
}

// LEFT LIST DIV
.left_list_div {
    display: flex;
    justify-content: start;
    // justify-content: center;
}

.names_wraper {
    margin-left: 1.1rem;

}

.picture {
    display: flex;
    align-items: center;
}

.img {
    width: 62px;
    height: 62px;
    // width: 65px;
    // height: 65px;
}

.name_div {
    display: flex;
    align-items: center;
    justify-content: start;
}

.name {
    
    // font-size: 1.3rem;
    font-size: 1.1rem;
    font-weight: 700;
}

.small_text {
    font-size: 0.9rem;
    // font-size: 0.8rem;
}



// RIGHT LIST DIV
.right_list_div {
    display: flex;
    justify-content: space-between;
}

.button_div,
.date {
    display: flex;
    align-items: center;
}

.date {
    font-weight: 600;
    font-size: 0.9rem;
    margin-right: 1.1rem;

}

.button {
    border-radius: 5px;
    padding: 0.7rem 1.2rem;
    font-size: 0.9rem;
    // font-size: 0.7rem;
    color: white;
    background-color: #2983FD;
    // border-color: #2983FD;
    border: none;
    outline: none;

}

.phone_date {
    display: none;
}

.navigation_div {
    display: none;
}

.next,
.back {
    display: flex;
    // justify-content: center;
    align-items: center;
    width: 20%;
}

.back {
    justify-content: start;
}

.navigation_title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;

    color: #2983FD;
    font-weight: 600;
}

.back_img {
    height: 20px;
    width: 20px;
}

.menu_dots_div {
    display: flex;
    align-items: center;
    justify-content: start;
}

.img_menu_dots {
    cursor: pointer;
    height: 30px;
    width: 20px;
}

// MEDIA QUERIES
@media (max-width: 768px) {

    .title {
        font-size: 1.1rem;
    }

    .main_list_div {
        margin: 2rem 0rem;
    }

    .left_list_div {

        width: 70%;
    }

    .navigation_div {
        display: flex;
        // justify-content: space-between;
        margin-bottom: 2rem;
    }

    .desktop_date {
        display: none;
    }

    .phone_date {
        display: block;
    }

    .main_container {
        margin-top: 0.5rem;
        padding: 0rem 1.7rem;
    }

    .img {
        width: 45px;
        height: 45px;
    }

    .name {
        font-size: 1rem;
    }

    .small_text {
        font-size: 0.8rem;
    }

    .date {
        font-size: 0.6rem;
        margin-right: 0.4rem;
    }

    .names_wraper {
        margin-left: 0.9rem;
    }

    .button {
        font-size: 0.8rem;
        padding: 0.5rem 1.3rem;
        // padding: 0.7rem 1.5rem;
    }


    .right_div_search {
        width: 100%;
    }

    .left_div_search {
        display: none !important;
    }
}