@import '../../variables';

.document-uploader {
  border: 2px dashed $lightBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  &:hover {
    background: $lightgray;
  }

  input[type="file"] {
    opacity: 0;
    cursor: pointer;
    padding: 1.2rem;
    width: -webkit-fill-available;
  }

  .custom-file-upload {
    color: $mainBlue;
    display: flex;
    padding: 6px 12px;
    cursor: pointer;
    position: absolute;
    margin: 0;

    img {
      margin-right: 2rem;
    }
  }
}

