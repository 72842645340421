.main_container {
    // padding: 0px 15px;
    padding: 0px 1rem;
}

.main_div {
    // border-radius: 10px;
    // background-color: #b3b3b3; // just for testing purposes
    // padding: 0.5rem 1.2rem;
}

.title_div {
    display: flex;
    margin-top: 2rem;
}

.title {
    font-size: 1rem;
    font-weight: 800;
    margin-bottom: 2px;
}

.search_div {
    margin-top: 1rem;
    width: 65%;
}

.user_connections_container {
    // margin-top: 1rem;
}

// List 

.main_list_div {
    display: flex;
    justify-content: space-between;
    // margin: 1.7rem 0rem;
    margin: 2.2rem 0rem;

}


// LEFT LIST DIV
.left_list_div {

    display: flex;
    // justify-content: center;
    justify-content: start;
    width: 75%;
}

.picture {
    display: flex;
    align-items: center;
}

.img {
    // width: 45px;
    // height: 45px;

    object-fit: cover;
    width: 75px;
    height: 75px;
    border-radius: 100%;
}



.name_div {
    display: flex;
    align-items: center;
}

.names_wraper {
    margin-left: 1.1rem;
}

.name {
    letter-spacing: 0.5px;
    font-weight: 700;
    white-space: nowrap;

    display: block;
    margin-bottom: 0.3rem;

}

.small_text {
    font-size: 0.7rem;
    line-height: normal;
    white-space: nowrap;

}



// RIGHT LIST DIV
.right_list_div {

    display: flex;
    // justify-content: space-between;
    justify-content: end;
    align-items: center;
    width: 24%;
}


.verify_profile_div {
    display: flex;
    align-items: center;
    // margin-right: 6rem;
}

.verify_profile_button {
    border-radius: 5px;
    padding: 0.6rem 2rem;
    font-size: 0.7rem;
    color: white;
    background-color: #07B997;
    border: none;
    // outline: none;
    // background: none;

}

.message_div {
    display: flex;
    align-items: center;
}


// .message_button_phone {
//     display: none;
// }

.friend_button,
.message_button {
    border-radius: 5px;
    padding: 0.6rem 2rem;
    font-size: 0.7rem;
    color: #2983FD;
    background-color: white;
    // border-color: #2983FD;
    border: 1px solid #2983FD;

    padding: 0.6rem 0rem;
    width: 120px;
}

.friend_button {
    color: salmon;
    background-color: white;
    border: 1px solid salmon;
    font-weight: 600;
}

.menu_dots_div {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-left: 0.5rem;
}

.img_menu_dots {
    cursor: pointer;
    height: 30px;
    width: 20px;
}

.show_more_users_div {
    display: flex;
    justify-content: center;
}

.show_more_users_button {
    border-radius: 5px;
    padding: 0.5rem 0rem;
    font-size: 0.7rem;
    color: #2983FD;
    background-color: white;
    border: 1px solid #2983FD;
    width: 60%;

    // margin-top: 2rem;
    margin-bottom: 1.2rem;
}

:hover.show_more_users_button {
    color: white;
    background-color: #2983FD;
    border: 1px solid #2983FD;
}

// MEDIA QUERIES
@media (max-width: 768px) {
    .main_container {
        margin: 0.5rem 0rem 0rem 0rem;
        // padding: 0rem 1.7rem; // original
        padding: 0rem 0.7rem;
    }

    .main_div {
        padding: 0.5rem 1rem;
    }

    .search_div {
        margin-top: 1rem;
        width: 100%;
    }

    .img {

        width: 55px;
        height: 55px;

    }

    .name {
        letter-spacing: 0.3px;
        font-size: 1rem;
        font-weight: 700;
        margin-bottom: 0.2rem;

    }

    .small_text {
        font-size: 0.7rem;
        line-height: normal;
    }

    // .right_list_div {
    //     // justify-content: end;
    //     width: 25%;
    // }

    // .message_button,
    // .verify_profile_button {
    //     font-size: 0.8rem;
    //     padding: 0.5rem 1.3rem;
    // }
    //-----------------------------------
    // .message_button {
    //     display: none;
    // }
    
    // .message_button {
    //     display: inline-block;      
    // }

    //-----------------------------------

    .friend_button,
    .message_button {
        font-size: 0.8rem;
        padding: 0.5rem 0rem;
        width: 80px;
    }
    
    // .friend_button,
    // .message_button_phone {
    //     font-size: 0.8rem;
    //     padding: 0.5rem 0rem;
    //     width: 80px;
    // }

    .menu_dots_div {
        display: flex;
        align-items: center;
        justify-content: start;
        margin-left: 0.1rem;
    }


    // .img_menu_dots{
    //     cursor: pointer;
    //     height: 30px;
    //     width: 20px;
    // }
}