@import '../../variables';

.label {
  display: block;
  position: relative;
  padding-left: 1.5rem;
  width: fit-content;
  cursor: pointer;
  font-size: 0.7rem;
  user-select: none;
}

.label-large {
  margin-top: -5px;

  .label {
    font-size: 1rem;
  }

  .checkmark {
    top: 3px
  }
}

/* Hide the browser's default checkbox */
.label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 2px;
  border: 1px solid $mainBlue;
  transition: 0.2s;
}

/* On mouse-over, add a grey background color */
.label:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.label input:checked ~ .checkmark {
  background-color: $mainBlue;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.label input:checked ~ .checkmark:after {
  display: block;
}

.label .checkmark:after {
  left: 4px;
  width: 5px;
  height: 10px;
  border: solid white;

  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}