@import "../../variables";

.contracting {
  background: #f5f5f5;

  .recent-documents {
    padding: 3rem;

    .doc-list {
      flex-wrap: nowrap;
      overflow-x: scroll;

      .document {
        margin: 1rem;
        padding: 1rem;
        cursor: pointer;

        .doc-title {
          padding: 1rem 0;
          color: $fontColor;
        }
      }
    }

    ::-webkit-scrollbar {
      height: 4px;

    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;

      background: $borderGray;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $mainBlue;
      border-radius: 10px;
      height: 8px !important;
      cursor: pointer;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: $textGray;

    }

  }
}