@import "../../variables";

.progress-bar1 {
  .bar {
    background: $borderGray;
    border-radius: 25px;
    height: 5px;
    width: 100%;

    .progress-value {
      background: transparent linear-gradient(271deg, #2983FD41 0%, #2983FD 57%, #2983FD 100%) 0% 0% no-repeat padding-box;
      border-radius: 25px;
      height: 5px;
    }
  }

  .progress-status {
    display: flex;
    justify-content: flex-end;

    span {
      font-size: 0.7rem;
      margin: 0.4rem 0;
    }
  }
}