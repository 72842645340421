.lng {
  font-size: 0.7rem;
  font-weight: 600;
}

.nav-bar {
  margin-top: 1rem;

  img {
    width: 55px;
  }
}