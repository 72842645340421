@import '../../variables';


.dashboard-inner-container {
  padding: 0 5rem 0rem 5rem; // za da ima padding od desno
  // padding: 0 5rem 2rem 5rem; // za da ima padding od desno
  // padding: 0 0rem 2rem 5rem; // original

  .media-section {
    width: 60%;
    margin: auto;
    min-width: 340px;
    max-width: 750px;
  }

  .side-section {
    width: 25%;
    min-width: 250px;
    max-width: 350px;
  }
}

.edit-link, .footer-link {
  font-size: 0.9rem;
  color: $mainBlue;

}

.dashboard-homepage {
  display: flex;
  justify-content: space-between;
}

// Mine added
@media screen and (max-width: 768px) {
  .dashboard-inner-container {
    padding: 0 0rem 2rem 0rem;
  }
}