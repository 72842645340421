@import "../../variables";

.info-box {
  padding: 1rem 0.5rem;
  background: $whiteGray;
  width: 100%;
  height: auto;
  margin: 3rem 0;

  .info-box-title {
    font-size: 1.5rem;
    margin: 1rem 0;
  }

  .info-icon {
    width: 19px;
    height: 19px;
    border: 2px solid $mainBlue;
    border-radius: 50%;
    font-size: 12px;
    color: $mainBlue;
    font-weight: bold;
  }
}

