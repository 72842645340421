@import "../../../variables";

.advanced-search-bar {
  width: 100%;
  border: 1px solid $lightgray;
  margin-bottom: 2.5rem;

  .advanced-search-field {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;

    .advanced-input {
      width: 100%;
      height: 100%;
      border: none;

      &:focus {
        outline: none;
      }

    }
  }

  .quick-filters {
    padding: 0.8rem 2rem;
  }
}

.advanced-search-modal {
  .advanced-search-modal-fields {
    overflow-y: scroll;
    max-height: 75vh;

  }

  ::-webkit-scrollbar {
    width: 8px;

  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $borderGray;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

