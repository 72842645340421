@import '../../variables';

.button {
  padding: 0.5rem 2.5rem;
  border-radius: 5px;
  border: 1px solid $mainBlue;
  font-size: 0.8rem;
}

button[disabled] {
  opacity: 0.5;
}

.small-btn {
  padding: 0.5rem 1rem;
}

.btn-blue {
  background-color: $mainBlue;
  color: $white;
}

.btn-verification-green {
  background-color: $verificationGreen;
  color: $white;
}

.btn-white {
  background-color: $white;
  color: $mainBlue;
  border-color: $mainBlue;
}

.btn-gray {
  background-color: $white;
  color: $borderGray;
  border-color: $borderGray;
}

.btn-red {
  background-color: $white;
  color: $lightRed;
  border-color: $lightRed;
}

.btn-transparent {
  background-color: transparent;
  color: $mainBlue;
  border-color: $mainBlue;
}

// mine added
.btn-widht-max-stretch {
  width: 100%;
}

.custom-small-btn {
  padding: 0.3rem 1.5rem;
}