@import '../../../../variables';

.ww {
    border: 1px solid blue;
}


.border_gray {
    border: 1px solid $borderGray;
    border-radius: 1rem;

}

.form_box {
    margin-bottom: 3rem;
    width: 100%;
    height: auto;
    // padding: 0.8rem 1rem;
    padding: 1rem 1rem 1.5rem 1rem;
}

.view_profile_form_title {
    font-size: 1.2rem;
    font-weight: 600;
}

.view_profile_form_small_title {
    font-size: 0.9rem;
    font-weight: 600;
}

.view_profile_form_normal_text {
    font-size: 0.8rem;
}


// Just for Experience
.view_profile_form_string_with_commas_text {
    word-spacing: 5px;
    font-size: 0.8rem;
}



@media (max-width: 768px) {

  
  }
  