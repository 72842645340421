@import "../../variables";

.media-box {
  .media-profile-picture {
    margin-right: 1rem;

    width: 4rem;
    height: 4rem;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  .media-shared-link {

    .media-image {
      width: 100%;
      height: calc(100vw * 0.20);
      min-height: 150px;
      max-height: 330px;
      background-size: cover;
      background-position: center;
    }

    .shared-link-desc {
      background: $lightgray;
      margin-bottom: 1rem;

      p {
        margin-bottom: 0;
      }

    }
  }

  .likes-comments-info {
    div {
      background: $borderGray;
      padding: 0.5rem;
      width: 26px;
      height: 26px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      margin-right: 0.5rem;

      img {
        width: 16px;
      }
    }
  }

  .media-actions {
    border-top: 1px solid $borderGray;
    padding: 1.5rem 0 0;

    a {
      margin-right: 2rem;
      color: $mainBlue;

      img {
        margin-right: 0.5rem;
        margin-top: -5px;
      }
    }

  }
}