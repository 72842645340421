@import "../../variables";

.menu-bar {
  position: absolute;
  top: 110px;
  // padding: 0 1rem;

  .logo {
    padding: 2rem 1rem;

    img {
      width: 45px;
    }
  }

  .menu-profile-box {
    background: $white;
    padding: 1.5rem 1rem;
    margin-bottom: .5rem;


    .menu-profile-box-img {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;

      img {
        width: 100%;
        border-radius: 50%;

      }
    }
  }

  .menu-box-alias {
    padding: 0.5rem 1rem;
  }

  .menu-actions {
    padding: 1.5rem 1rem;

    .menu-action {

      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      padding: 1rem;
      margin: 1rem;
      transition: 0.4s;
      position: relative;

      &:hover {
        // background: $whiteGray;

        .menu-icon-name {
          display: block;
        }
      }

      .menu-icon-name {
        display: none;
        position: absolute;
        left: 50px;
        padding: 5px 10px;
        font-size: 0.7rem;
        color: $white;
        background: $mainBlue;
        border-radius: 2px;
        z-index: 10;

        .menu-icon-arrow {
          width: 7px;
          height: 7px;
          background: $mainBlue;
          transform: rotate(45deg);
          position: absolute;
          left: -3px;
          top: 10px;
        }
      }
    }

    .active-icon {
      background: $whiteGray;
    }
  }

  .menu-footer {
    position: fixed;
    bottom: 15px;
    // left: 55px;
    left: 3.5vw;
    width: 150px;
    // border: 3px solid #73AD21;
  }

  // .menu-footer {
  //   padding: 1rem 0;

  //   a {
  //     margin-bottom: 0.1rem;
  //   }
  // }
  .menu-footer div,
  .menu-footer a {
    display: block;
    // color: red;
    font-size: 0.6rem;
    font-weight: 500;
    margin-bottom: 0.2rem;

  }

  .menu-footer .footer-name {
    color: rgba(130, 130, 130, 0.8);
    font-size: 0.6rem;
    font-weight: 600;

  }
}




.manage-menu-dropdown {
  top: 90px;
  right: 40px;

  .manage-menu {
    z-index: 100;
    box-shadow: 5px 6px 20px -5px rgba(0, 0, 0, 0.3);

    .menu-profile-box {
      min-width: 350px;
      max-width: 350px;
      align-items: center;
      margin: 0;
      font-size: 0.8rem;
      word-break: break-word;


      .account-name {
        font-size: 0.9rem;
      }
    }


  }


}