@import '../../variables';

.table-employees {
  position: relative;

  .table-action-buttons {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1;

  }

  .MuiPaper-elevation4 {
    box-shadow: none !important;
  }

  .MuiPaper-rounded {
    border-radius: 10px !important;
  }

  .MUIDataTableToolbar-icon-26:hover {
    color: $mainBlue !important;
  }

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: $mainBlue !important;
  }

  .MuiTable-root caption, .MuiTableCell-root {
    color: $fontColor !important;
    font-family: $fontFamily !important;
  }

  .MuiTableCell-head {
    font-weight: bold !important;
  }

  .MuiTypography-h6 {
    font-size: 1.3rem;
    color: $fontColor !important;
    font-family: $fontFamily !important;

  }

  .MUIDataTableToolbar-actions-21 {
    display: none;
  }

  .MUIDataTableToolbarSelect-iconButton-94 {
    display: none;
  }

  .MUIDataTableToolbarSelect-root-92 {
    width: 170px;
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
  }

}