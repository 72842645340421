@import "../../variables";

.input-autocomplete {
  position: relative;

  .autocomplete {
    width: 100%;
    position: absolute;
    background: $white;
    padding: 0.5rem 0;
    border-radius: 5px;
    border: 1px solid $borderGray;
    z-index: 2;

    .autocomplete-item {
      color: $fontColor;
      font-size: 0.9rem;
      padding: 0.2rem 0.5rem;

      &:hover {
        background: $lightgray;
        cursor: pointer;
      }
    }
  }

}