@import "../../variables";


.comment {
  position: relative;

  .comment-img {
    position: relative;
    z-index: 1;
  }

  .comment-content {
    position: relative;
    top: -15px;
    left: 25px;

    .ck-editor__editable {
      min-height: 100px !important;
      border: none !important;
    }

    .ck.ck-editor__editable_inline {
      padding: 0 1rem !important;
    }
  }
}

.create-post-project {
  .ck-editor__editable {
    min-height: 200px !important;

  }

  .ck.ck-editor__editable_inline {
    padding: 0 1rem !important;
  }
}