.left_big_Container {
    display: flex;
    justify-content: end;
    margin-top: 2.5rem;
}

.left_big_div {
    width: 85%;
}

.right_big_Container {
    display: flex;
    justify-content: start;
    margin-top: 2.5rem;
}

.right_big_div {
    width: 85%;
}

.welcome_div {
    margin-bottom: 1rem;
}

.welcome {
    font-size: 1.3rem;
    font-weight: 800;
}

.anti_money_laundry_wrapper {
    margin: 0.7rem 0rem;
}

.anti_money_laundry_text {
    font-size: 0.9rem;
    font-weight: 700;
}

.check_network_connections_wrapper {
    width: 70%;
}

.check_network_connections {
    letter-spacing: 0.2px;
    font-size: 0.7rem;
    line-height: normal;
}

.you_have_permission_for_this_alias_wrapper {
    display: flex;
    justify-content: start;
    margin: 0.8rem 0rem;
}

.you_have_permission_img_div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.you_have_permission_text_div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.you_have_permission_text {
    font-size: 0.8rem;
    font-weight: 600;
    margin-left: 1rem;
}

.you_have_permission_img {
    height: 1.6rem;
    width: 1.6rem;
}

.you_have_purchased {
    font-size: 0.8rem;
    font-weight: 600;
}

.you_have_purchased_div {
    display: flex;
}

// Right - Div - Components


.all_alias_wraper {
    margin-top: 0.2rem;
}

.get_alias_div {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.get_alias_text {
    font-size: 0.9rem;
    font-weight: 700;
}

.arrow_img {
    width: 1rem;
    height: 1rem;

}

//------------------------



.all_alias_div {
    display: flex;
    align-items: center;
    justify-content: start;
    margin: 1.2rem 0rem;
    padding: 0.1rem 0rem;

}

.left_list_div {
    display: flex;
    justify-content: center;
}

.alias_picture {
    display: flex;
    align-items: center;

}

.alias_img {
    height: 1.8rem;
    width: 1.8rem;
}

.alias_name_wraper {
    margin-left: 0.6rem;
}

.alias_name_div {
    display: flex;
    align-items: center;
}

.alias_name {
    letter-spacing: 0.18px;
    font-size: 0.7rem;
    font-weight: 600;
}

.alias_small_text {
    color: #2983FD;
    font-size: 0.6rem;
    line-height: normal;
}

@media (max-width: 768px) {

    .right_big_div,
    .left_big_div {
        width: 100%;
        justify-content: center;

    }

    .check_network_connections_wrapper {
        width: 100%;
    }

    .left_big_Container {
        justify-content: center;
        margin-top: 1.2rem;
    }

    .right_big_Container {
        justify-content: center;
        margin-top: 1.2rem;
    }
}