@import "src/variables";

.form_box_section {
  // padding: 0.2rem 0.5rem;
  // border: 1px solid salmon;
}

.form_box_title_div{
 
}

.form_box_title{
  background-color: #f8f8f8;
  border-radius: 5px;

  padding: 0.5rem 1rem;
  // margin: 0.6rem 0rem;
  border: 1px solid #f8f8f8;
  font-size: 1.2rem;
  font-weight: 700 !important;
  color: black !important;
}
