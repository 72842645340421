.cards-div {
    margin-top: 0px;
    // border: 1px solid blue;
    padding: 0px 0px 0px 0px;
    // margin-right: 50px;
    width: 75%;
    margin-bottom: 20px;

    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-flow: wrap;

}

.edit-div,
.verification-div {
    box-sizing: border-box;
    // border: 1px solid salmon;
    width: 100%;
    height: 270px;
    // margin-bottom: 120px;
    // margin-right: 20px;
}

.edit-div {
    margin-bottom: 60px;
}

.edit-button,
.verification-button {
    color: white;
    padding: 0px 15px 0px 15px;
    height: 40px;
    float: right;
    font-size: 13px;
    border-radius: 5px;
}

.edit-button {
    background-color: #2983FD;
    border: 1px solid #2983FD;
}

.verification-button {
    background-color: #07B997;
    border: 1px solid #07B997;
}


.big-text-verification,
.big-text-edit {
    color: black;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 20px;
}

.verification-profile-title,
.edit-profile-title {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 15px;
}

.edit-profile-title {
    color: #3DA3FE;
}

.verification-profile-title {
    color: #07B997;
}



.y {

    position: relative;
    width: 100%;
    height: 100%;
    // border: 1px solid black;
}

.y-child {
    position: absolute;
    bottom: 0;
    width: 100%;
    // height: 50px;
    // border: 1px solid red;
}

ol li {
    list-style-type: decimal;
}

ol {
    padding: 0px 0px 0px 15px;
}




@media only screen and (min-width: 280px) {

    .cards-container {
        margin-top: 10%;
    }

    .edit-button,
    .verification-button {
        padding: 0px 8px 0px 8px;
        height: 25px;
        font-size: 10px;
    }

    .edit-div,
    .verification-div {

        height: 240px;

    }

    .verification-profile-title,
    .edit-profile-title {

        font-size: 13px;
    }

    .big-text-verification,
    .big-text-edit {
        font-size: 13px;
    }

    ol li {
        font-size: 10px;
    }
}

@media only screen and (min-width: 365px) {
    .cards-container {
        margin-top: 12%;
    }

    // .cards-div {
    //     margin-top: 20px;
    // }

    .edit-button,
    .verification-button {

        padding: 0px 15px 0px 15px;
        height: 40px;

        font-size: 12px;

    }

    .edit-div,
    .verification-div {

        height: 270px;

    }

    .verification-profile-title,
    .edit-profile-title {

        font-size: 15px;
        // margin-bottom: 15px;
    }

    .big-text-verification,
    .big-text-edit {
        font-size: 15px;
    }

    ol li {
        font-size: 13px;
    }

}

@media only screen and (min-width: 470px) {
    .cards-div {
        width: 70%;
    }

}

@media only screen and (min-width: 600px) {}

@media only screen and (min-width: 768px) {
    .cards-container {
        margin-top: 8%;
        height: 40vh;

    }

    .cards-div {
        width: 70%;
        justify-content: space-between;
    }

    .edit-div,
    .verification-div {
        box-sizing: border-box;
        width: 45%;
        // height: 100%;
        height: 100%;

    }

    .verification-profile-title,
    .edit-profile-title,
    .big-text-verification,
    .big-text-edit {
        font-size: 16px;
    }

    ol li {
        font-size: 14px;
    }

    .edit-button,
    .verification-button {

        padding: 0px 20px 0px 20px;
        height: 40px;

        font-size: 15px;

    }
}


@media only screen and (min-width: 992px) {
    .cards-container {
        margin-top: 2%;
    }

    .cards-div {
        width: 60%;

    }

}

@media only screen and (min-width: 1200px) {
    .cards-container {
        margin-top: 4%;
    }

    .cards-div {
        width: 60%;

    }

}

@media only screen and (min-width: 1550px) {
    .cards-container {
        margin-top: 6%;
    }

    .verification-profile-title,
    .edit-profile-title,
    .big-text-verification,
    .big-text-edit {
        font-size: 22px;
    }

    ol li {
        font-size: 18px;
    }

    .edit-button,
    .verification-button {

        padding: 0px 30px 0px 30px;
        height: 60px;

        font-size: 18px;

    }
}