@import "../../../variables";

.menu-bar {
  position: absolute;
  top: 110px;
  // padding: 0 1rem;

  .logo {
    padding: 2rem 1rem;

    img {
      width: 45px;
    }
  }

  .menu-profile-box {
    background: $white;
    padding: 1.5rem 1rem;
    margin-bottom: .5rem;


    .menu-profile-box-img {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;

      img {
        width: 100%;
        border-radius: 50%;

      }
    }
  }

  .menu-box-alias {
    padding: 0.5rem 1rem;
  }

  .menu-actions {
    padding: 1.5rem 1rem;

    .menu-action {

      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      padding: 1rem;
      margin: 1rem;
      transition: 0.4s;
      position: relative;

      &:hover {
        // background: $whiteGray;

        .menu-icon-name {
          display: block;
        }
      }

      .menu-icon-name {
        display: none;
        position: absolute;
        left: 50px;
        padding: 5px 10px;
        font-size: 0.7rem;
        color: $white;
        background: $mainBlue;
        border-radius: 2px;
        z-index: 10;

        .menu-icon-arrow {
          width: 7px;
          height: 7px;
          background: $mainBlue;
          transform: rotate(45deg);
          position: absolute;
          left: -3px;
          top: 10px;
        }
      }
    }

    .active-icon {
      background: $whiteGray;
    }
  }

  .menu-footer {
    // padding: 1rem 0;

    // a {
    //   margin-bottom: .5rem;
    // }
  }
}

.manage-menu-dropdown_2 {
  margin: 0px;
  padding: 0px;

  top: 70px;
  right: 20px;
  // top: 90px;
  // right: 40px;

  .manage-menu {
    z-index: 100;
    // box-shadow: 5px 6px 20px -5px rgba(0, 0, 0, 0.3);


    .menu-profile-box {
      // border: 1px solid salmon;
      min-width: 320px;
      max-width: 320px;
      align-items: center;
      margin: 0;
      font-size: 0.8rem;
      word-break: break-word;


      .account-name {
        font-size: 0.9rem;
      }
    }
  }
}



@media only screen and (min-width: 365px) {

  .edit-profile-btn,
  .view-profile-btn {
    background-color: #2983FD;
    padding: 5px 9px 5px 9px;
    border-radius: 6px;
    color: white;

  }

  .view-profile-btn b,
  .edit-profile-btn b {
    font-size: 10px;
  }
}

@media only screen and (max-width: 365px) {
  .manage-menu-dropdown_2 {
    top: 70px;
    right: 0px;
  }

  .menu-profile-box {
    min-width: 300px;
    max-width: 300px;
  }
}


// @media only screen and (max-width: 365px) {
//   .manage-menu-dropdown_2 {
//     top: 70px;
//     right: 200px;
//   }

// }
//----------------------------------