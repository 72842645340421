@import '../../variables';

.create-alias {

  // mine added
  .create-profile {
    color: white;
    background-color: #2983FD;
    padding: 0.6rem 1rem;
    border-radius: 5px;
  }

  //----------------
  .alias-title {
    border-bottom: 1px solid $borderGray;
    padding-left: 2rem;
    margin-bottom: 3rem;

  }

  .alias-gray-box {
    background: $lightgray;
    border: none;
    margin-bottom: 3rem;

    .alias-link {
      text-decoration: none;

      &:hover {
        .alias-box {
          box-shadow: 0px 6px 20px #00000029;
        }
      }

      .alias-box {
        transition: 0.4s;
        background: $white;
        border: 1px solid $borderGray;
        margin: 3rem auto;
        max-width: 26rem;

        .alias-text {
          min-height: 150px;
          padding: 0 1rem;
        }

        img {
          height: 175px;
        }

        h5 {
          margin-top: 0.7rem;
          color: $fontColor;
        }

        p {
          font-size: 0.75rem;
          color: $textGray;
        }
      }
    }
  }

}

.check-mark-img {
  height: 0.7rem !important;
  width: 0.7rem !important;
  margin-right: 0.3rem;
  padding-bottom: 0.2rem;
}

.check-mark-p {
  margin: 0px;
  padding: 0px;
}