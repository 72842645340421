@import "../../variables";

.ck-wrapper {
  height: 100%;
}

.ck-editor__editable {
  min-height: 100vh !important;
  border: 1px solid $borderGray !important;
}

.ck.ck-editor__editable_inline {
  padding: 2rem 5rem !important;
}
