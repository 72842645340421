@import '../../variables';

.create-post {
  padding: 1rem 3rem;

  i {
    margin-right: 1rem;
    color: $mainBlue;
  }

  .create-btn {
    button {
      border: none;
      background: $white;
      padding: 1rem 1rem;
      font-size: 1.5rem;
      width: 100%;
      text-align: left;
    }

  }

  .post-type-btns {
    padding: 1rem;
    padding-bottom: 0.5rem;
    border-top: 1px solid $borderGray;

    div {
      margin-right: 1rem;

      button {
        border: none;
        background: $white;

      }
    }

  }
}